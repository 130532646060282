<template>
  <component :is="layout"></component>
</template>
<script>
  import IndexLayout from './components/layouts/Index'
  import PrincipalLayout from './components/layouts/Principal'
  import {mapState} from 'vuex'
  export default {
    components: {IndexLayout, PrincipalLayout},
    computed: mapState(['layout'])
    /*
    data () {
      return {
        layout: 'index-layout'
      }
    }
    */
  }
</script>