import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        layout: 'index-layout',
        asegurado: {
            documento : '', 
            nombre: '', 
            apellido: '', 
            fechaNacimiento : '', 
            aseguradoPagador : '', 
            direccion : '', 
            email : '', 
            telefono : '', 
            region : '', 
            comuna : ''
        },
        vehiculo: {
            marca : '', 
            modelo: '', 
            anio: '', 
            patente : '', 
            tipoVehiculo : '', 
            tipoUso : '', 
            color : '', 
            chasis : '', 
            motor : ''
        },
        pagador: {
            documento : '', 
            nombre: '', 
            apellido: '', 
            correo : '', 
            telefono : ''
        },
        tarifas : [],
        conversion : {
            fecha : '', 
            valor : ''
        },
        cotizacion: 0,
        compania: 0,
        isLoadImpulsa: false
    },
    mutations: {
        SET_LAYOUT (state, newLayout) {
            state.layout = newLayout
        },
        SET_IMPULSA (state, newValue) {
            state.isLoadImpulsa = newValue
        },
        setAsegurado: (state, asegurado) => {
            state.asegurado.documento = asegurado.documento
            state.asegurado.nombre = asegurado.nombre
            state.asegurado.apellido = asegurado.apellido
            state.asegurado.fechaNacimiento = asegurado.fechaNacimiento
            state.asegurado.aseguradoPagador = asegurado.aseguradoPagador
            state.asegurado.email = asegurado.email
            state.asegurado.telefono = asegurado.telefono
            state.asegurado.region = asegurado.region
            state.asegurado.comuna = asegurado.comuna
        },
        setAseguradoEmision: (state, asegurado) => {
            state.asegurado.direccion = asegurado.direccion
            //state.asegurado.email = asegurado.email
            //state.asegurado.telefono = asegurado.telefono
            //state.asegurado.region = asegurado.region
            //state.asegurado.comuna = asegurado.comuna
            
        },
        setVehiculo: (state, vehiculo) => {
            state.vehiculo.marca = vehiculo.marca
            state.vehiculo.modelo = vehiculo.modelo
            state.vehiculo.anio = vehiculo.anio
            state.vehiculo.patente = vehiculo.patente
            state.vehiculo.tipoVehiculo = vehiculo.tipoVehiculo
        },
        setVehiculoEmision: (state, vehiculo) => {
            state.vehiculo.tipoUso = vehiculo.tipoUso
            state.vehiculo.color = vehiculo.color
            state.vehiculo.chasis = vehiculo.chasis
            state.vehiculo.motor = vehiculo.motor
        }, 
        setPagador: (state, pagador) => {
            state.pagador.documento = pagador.documento
            state.pagador.nombre = pagador.nombre
            state.pagador.apellido = pagador.apellido
            state.pagador.correo = pagador.correo
            state.pagador.telefono = pagador.telefono
        },
        setTarifas : (state, tarifas) => {   
            state.tarifas = []     
            tarifas.forEach(tarifa => {
                state.tarifas.push(tarifa)
            })
        }, 
        setconversion: (state, conversion) => {
            state.conversion.fecha = conversion.fecha
            state.conversion.valor = conversion.valor
        }
    },
    actions: {

    }
})
