<template>
  <v-container>
      <div>
        <v-form ref="form" v-model="valid" lazy-validation>
        <div class="d-flex mb-5">
           <v-layout >
            <v-flex >
                <h1 class="title">Resumen de Contratación</h1>
            </v-flex>
          </v-layout>
        </div>
        <v-divider class="mb-5"></v-divider>

        <v-expansion-panels
          v-model="panel"
          :readonly="readonly"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header>Asegurado</v-expansion-panel-header>
            <v-divider class="mb-5"></v-divider>
            <v-expansion-panel-content>
              
                <v-row justify="space-between">
                  <v-col
                    cols="12"
                    xs="4"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="mAsegurado.documento"
                      label="NºRut"
                      :rules="rutRules"
                      :disabled="true"
                    ></v-text-field>
                    
                  </v-col>
                  <v-col
                        cols="12"
                        xs="4"
                        sm="6"
                        md="4"
                      >
                          <v-text-field
                            v-model="mAsegurado.nombre"
                            label="Nombre"
                            :rules="rulesNombre"
                            :disabled="true"
                          ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="4"
                        sm="6"
                        md="4"
                      >
                          <v-text-field
                            v-model="mAsegurado.apellido"
                            label="Apellido"
                            :rules="rulesApellido"
                            :disabled="true"
                          ></v-text-field>
                      </v-col>
                  <v-col
                    cols="12"
                    xs="4"
                    sm="6"
                    md="4"
                  >
                    <v-autocomplete
                      :items="region"
                      v-model="mAsegurado.region"
                      :rules="rulesRegion"
                      label="Region"
                      v-on:change="defineComuna(mAsegurado.region)"
                      :disabled="true"
                    ></v-autocomplete>

                  </v-col>
                  <v-col
                    cols="12"
                    xs="4"
                    sm="6"
                    md="4"
                  >
                    <v-autocomplete
                      :items="comuna"
                      v-model="mAsegurado.comuna"
                      :rules="rulesComuna"
                      label="Comuna"
                      :disabled="true"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                  cols="12"
                  xs="4"
                  sm="6"
                  md="4"
                  >
                    <v-text-field
                      v-model="mAsegurado.direccion"
                      :rules="rulesDireccion"
                      label="Direccion"
                      :disabled="false"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    xs="4"
                    sm="6"
                    md="4"
                  >
                      <v-text-field
                        v-model="mAsegurado.email"
                        :rules="rulesCorreo"
                        label="Email"
                        :disabled="false"
                      ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    xs="4"
                    sm="6"
                    md="4"
                  >
                      <v-text-field
                        v-model="mAsegurado.telefono"
                        :rules="rulesTelefono"
                        label="Telefono"
                        v-mask="maskF"
                        :disabled="false"
                      ></v-text-field>
                  </v-col>
                </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="mb-5">
            <v-expansion-panel-header>Datos Pagador</v-expansion-panel-header>
            <v-divider class="mb-5"></v-divider>
            <v-expansion-panel-content>
                <v-row justify="space-between">
                      <v-col
                        cols="12"
                        xs="4"
                        sm="6"
                        md="4"
                      >
                          <v-text-field
                            v-model="mPagador.documento"
                            :rules="rutRules"
                            label="NºRut"
                            :disabled="true"
                          ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="4"
                        sm="6"
                        md="4"
                      >
                          <v-text-field
                            v-model="mPagador.nombre"
                            :rules="rulesNombre"
                            label="Nombre"
                            :disabled="true"
                          ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="4"
                        sm="6"
                        md="4"
                      >
                          <v-text-field
                            v-model="mPagador.apellido"
                            :rules="rulesApellido"
                            label="Apellido"
                            :disabled="true"
                          ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="4"
                        sm="6"
                        md="4"
                      >
                          <v-text-field
                            v-model="mPagador.correo"
                            :rules="rulesCorreo"
                            label="Correo"
                            :disabled="false"
                          ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="4"
                        sm="6"
                        md="4"
                      >
                          <v-text-field
                            v-model="mPagador.telefono"
                            :rules="rulesTelefono"
                            label="Telefono"
                            v-mask="maskF"
                            :disabled="false"
                          ></v-text-field>
                      </v-col>
                </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
            <v-expansion-panel class="mb-5">
            <v-expansion-panel-header>Medio de Pago</v-expansion-panel-header>
            <v-divider class="mb-5"></v-divider>
            <v-expansion-panel-content>
                <v-row justify="space-between">
                      <v-col
                        cols="12"
                        xs="4"
                        sm="6"
                        md="4"
                      >
                          <v-text-field
                            v-model="mMedioPago.numero"
                            :rules="rulesMedioPagoNumero"
                            label="Número Tarjeta de Crédito"
                            v-mask="'################'"
                            :disabled="false"
                          ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="4"
                        sm="6"
                        md="4"
                      >
                          <v-text-field
                            v-model="mMedioPago.vencimiento"
                            :rules="rulesMedioPagoVencimiento"
                            label="Vencimiento (MM/AAAA)"                            
                            v-mask="'##/####'"
                            :disabled="false"
                          ></v-text-field>
                      </v-col>
                </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Vehiculo</v-expansion-panel-header>
            <v-divider class="mb-5"></v-divider>
            <v-expansion-panel-content>
                <v-row justify="space-between">
                      <v-col
                      cols="12"
                      xs="4"
                      sm="6"
                      md="4"
                      >
                        <v-text-field
                          v-model="mVehiculo.tipoUso"
                          :rules="rulesTipoUso"
                          label="Tipo de Uso"
                          :disabled="true"
                        ></v-text-field>

                      </v-col>
                      <v-col
                      cols="12"
                      xs="4"
                      sm="6"
                      md="4"
                      >
                        <v-text-field
                          v-model="mVehiculo.color"
                          :rules="rulesColor"
                          label="Color"
                          :disabled="false"
                        ></v-text-field>
                      </v-col>
                      <v-col
                      cols="12"
                      xs="4"
                      sm="6"
                      md="4"
                      >
                        <v-text-field
                          v-model="mVehiculo.chasis"
                          :rules="rulesChasis"
                          label="Chasis"
                          :disabled="false"
                        ></v-text-field>
                      </v-col>
                      <v-col
                      cols="12"
                      xs="4"
                      sm="6"
                      md="4"
                      >
                        <v-text-field
                          v-model="mVehiculo.motor"
                          :rules="rulesMotor"
                          label="Nº Motor"
                          :disabled="false"
                        ></v-text-field>
                      </v-col>
                      <v-col
                      cols="12"
                      xs="4"
                      sm="6"
                      md="4"
                      >
                        <v-text-field
                          v-model="mVehiculo.marca"
                          :rules="rulesMarca"
                          label="Marca"
                          :disabled="true"
                        ></v-text-field>
                      </v-col>
                      <v-col
                      cols="12"
                      xs="4"
                      sm="6"
                      md="4"
                      >
                        <v-text-field
                          v-model="mVehiculo.modelo"
                          :rules="rulesModelo"
                          label="Modelo"
                          :disabled="true"
                        ></v-text-field>
                      </v-col>
                </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>
        
        <v-row
          align="center"
          justify="center"
          class="mt-5"
        >
          <v-btn @click="emitir(mAsegurado,mVehiculo, mPagador)" rounded x-large  color="#7c167d" dark>Contactar</v-btn>
        </v-row>

      </v-form>
      </div>
      <template>
      <div>
        <v-alert
          v-model="alert"
          border="left"
          close-text="Close Alert"
          type="warning"
          dark
          dismissible
        >
          {{MensajeError}}
        </v-alert>
      </div>
    </template>
    <template>
      <div>
        <v-alert
          v-model="ErrorSer"
          border="left"
          close-text="Close Alert"
          type="error"
          dark
          dismissible
        >
          {{ErrorServer}}
        </v-alert>
      </div>
    </template>

      <template>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
            :is-full-page="fullPage"></loading>
        </div>
      </template>
  </v-container>
</template>
<script>
import config from '../../config.js'
import { mapState, mapMutations } from 'vuex'
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
const { format, validate} = require('rut.js')
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'
//const moment = require('moment');
const cardValidator = require("card-validator");


  export default {
    components : {
      Loading
    },
    directives: {
      mask,
    },
    data: () => ({
      MensajeError: '',
      ErrorServer: '',
      alert: false,
      ErrorSer : false,
      panel: [0, 1, 2, 3],
      readonly: false,
      firstOption : null,
      secondOption : null,
      valid: false,
      rut: '',
      rulesMedioPagoNumero: [
        v => !!v || 'Número de tarjeta es requerido',
        v => cardValidator.number(v).isValid || 'Número de tarjeta no válido'
      ],
      rulesMedioPagoVencimiento: [
        v => !!v || 'Vencimiento de tarjeta es requerido',
        v => cardValidator.expirationDate(v).isValid || 'Tarjeta expirada'
        // v => moment(v, 'MM/YYYY').isValid() || 'Fecha no válida',
        // v => {
        //           if (v.length == 7 ) {
        //             let hoy = moment().format('YYYY/MM');
        //             let f = moment(v,'MM/YYYY').toJSON();
        //             if (moment(hoy).isBefore(f)) return true;
        //             else return 'Tarjeta vencida, no válida';
        //           } else {
        //             return ''
        //           }
        //     }
      ],
      rulesNombre: [
        v => !!v || 'Nombre Requerido',
      ],
      rulesApellido: [
        v => !!v || 'Apellido Requerido',
      ],
      patente: '',
      patenteRules: [
        v => !!v || 'Patente Requerida',
      ],
      rutRules: [
        v => validate(v) || 'NºRut Incorrecto'
      ],
      rulesRegion: [
        v => !!v || 'Region Requerida',
      ],
      rulesComuna: [
        v => !!v || 'Comuna Requerida',
      ],
      rulesDireccion: [
        v => !!v || 'Direccion Requerida',
      ],
      rulesTelefono: [
        v => !!v || 'Telefono Requerido',
      ],
      rulesCorreo: [
        v => !!v || 'Email Requerido',
      ],
      rulesTipoUso: [
        v => !!v || 'Tipo de uso Requerido',
      ],
      rulesColor: [
        v => !!v || 'Color Requerido',
      ],
      rulesChasis: [
        v => !!v || 'Chasis Requerido',
      ],
      rulesMotor: [
        v => !!v || 'Motor Requerido',
      ],
      rulesMarca: [
        v => !!v || 'Marca Requerida',
      ],
      rulesModelo: [
        v => !!v || 'Modelo Requerido',
      ],
      modelRegion: '',
      region : [],
      modelComuna: '',
      comuna: [],
      tipoUso:['Particular'],
      modelModelo:'',
      ModelMarca:'',
      regionH : [] , 
      mMedioPago: {
        numero:'',
        vencimiento:''
      },
      mAsegurado: {
            documento : '', 
            nombre: '', 
            apellido: '', 
            fechaNacimiento : '', 
            aseguradoPagador : '', 
            direccion : '', 
            email : '', 
            telefono : '', 
            region : '', 
            comuna : ''
      }, 
      mVehiculo: {
            marca :   '', 
            modelo:   '', 
            anio:     '', 
            patente : '', 
            tipoVehiculo : '', 
            tipoUso : '', 
            color :  '', 
            chasis : '', 
            motor : ''
      },
      mPagador: {
            documento : '', 
            nombre: '', 
            apellido: '', 
            correo : '', 
            telefono : ''
        }, 
     
     
     isLoading: false,
      fullPage: true, 
      maskF : '#########'
    }),
    created () {
      this.$store.commit('SET_LAYOUT', 'principal-layout'),
      this.inicial()
    },
    computed: {
      ...mapState(['asegurado', 'vehiculo', 'pagador'])
    },
    methods: {
      ...mapMutations(['setAsegurado','setPagador', 'setVehiculo']),
      async inicial(){
        await axios
        .get(`${config.API_URL}/api/cts/comunas`)
        .then(response => {
            //console.log("Obtiene comunas")
            response.data.communes.regiones.forEach(element => {
              let region = new Object()
              region.NombreRegion = element.NombreRegion
              region.numero = element.numero
              region.comunas = element.comunas
              this.regionH.push(region)
              this.region.push(element.NombreRegion)
            });
        })
        .catch(error => {
          console.log(error)
        })
        this.region.sort()
        console.log('REGION:',this.$store.state.asegurado.region);
        console.log('COMUNA:',this.$store.state.asegurado.comuna)

        this.defineComuna(this.$store.state.asegurado.region)
        this.setModelAsegurado(this.$store.state.asegurado)
        this.setModelVehiculo(this.$store.state.vehiculo)
        this.setModelPagador(this.$store.state.pagador)
      }, 
      defineComuna(region){
        //console.log("Valor de Region " + region);
        this.comuna = [];
        //console.log("Leyendo region " + this.regionH.length)
        this.regionH.forEach(element => {
          //console.log("Leyendo region " + element.NombreRegion)
          //console.log("Leyendo region " + region)
          //console.log("Antes de evaluar")
          if (element.NombreRegion === region){
            //console.log("Misma regios")
            element.comunas.forEach(comuna => {
              //console.log(comuna)
              this.comuna.push(comuna)
            })
          }else {
            console.log("No encontro comunas")
          }
        });

        this.comuna.sort()
      }, 
      setModelVehiculo(vehiculo){

          this.mVehiculo.marca = vehiculo.marca
          this.mVehiculo.modelo = vehiculo.modelo
          this.mVehiculo.anio = vehiculo.anio
          this.mVehiculo.patente = vehiculo.patente
          this.mVehiculo.tipoVehiculo = vehiculo.tipoVehiculo
          this.mVehiculo.tipoUso = vehiculo.tipoUso
          this.mVehiculo.color = vehiculo.color
          this.mVehiculo.chasis = vehiculo.chasis
          this.mVehiculo.motor = vehiculo.motor
      }, 
      setModelAsegurado(asegurado){
          this.mAsegurado.documento = format(asegurado.documento)
          this.mAsegurado.nombre = asegurado.nombre
          this.mAsegurado.apellido = asegurado.apellido
          this.mAsegurado.fechaNacimiento = asegurado.fechaNacimiento
          this.mAsegurado.aseguradoPagador = asegurado.aseguradoPagador
          this.mAsegurado.direccion = asegurado.direccion
          this.mAsegurado.email = asegurado.email
          this.mAsegurado.region = asegurado.region
          this.mAsegurado.comuna = asegurado.comuna
          this.mAsegurado.telefono = asegurado.telefono
          
      }, 
      setModelPagador(pagador){
          this.mPagador.documento = format(pagador.documento)
          this.mPagador.nombre = pagador.nombre
          this.mPagador.apellido = pagador.apellido
          this.mPagador.correo = pagador.correo
          this.mPagador.telefono = pagador.telefono
          
      },
      emitir(asegurado, vehiculo, pagador){

        if (this.$refs.form.validate()) { //this.valid) {
            this.alert = false 
            this.isLoading = true
            axios
            .post(`${config.API_URL}/api/cts/emision`,{
                asegurado : asegurado,
                vehiculo : vehiculo, 
                pagador : pagador,
                cotizacion : this.$store.state.cotizacion,
                medioPago: {
                  numero: this.mMedioPago.numero,
                  vencimiento: this.mMedioPago.vencimiento
                }
            })
            .then(response => {
              console.log(response)
              this.$router.push("emision")
            })
            .catch(error => {
              console.log('Error en emision');
              console.log(error);
              Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: 'A ocurrido un error al tratar de generar tu seguro. Intentalo más tarde!',                          
                          confirmButtonColor: '#99248c'
                        });            
            }).finally(() => this.isLoading = false);
        }else {
          //console.log("Error formulario")
          this.MensajeError = "Favor revisar los datos obligatorios a ingresar"
          this.alert = true
        }
        
      }
    }
  }
  </script>
