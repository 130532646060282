<template>
  <v-container
    class="fill-height white--text"
    fluid
  >
    <v-row
      align="center"
      justify="center"      
    >
      <v-col 
        cols="12"
        md="5"
        class="text-center">
        <v-row
          align="center"
          justify="center"
          style="height: 150px;"
        >
           <v-flex>
            <img ma-5 class="block-center rounded"
              align= "center"
              height="50px"
              max-width="100"
              max-height="100"
              src="img/logos/ComparaSeguroIndex.jpeg" alt="Image">
         
          </v-flex> 
          <!-- <v-flex>
            <img ma-5 class="block-center rounded"
              align="right"
              height="150px"
              max-width="100"
              max-height="100"
              src="img/logo_blanco_dias_cyber.png" alt="Image">
          </v-flex>
 -->
        
        <!-- <v-flex>
          <img ma-5 class="block-center rounded"
          height="50px"
          max-width="100"
          max-height="100"
          src="img/logos/ComparaSeguroIndex.jpeg" alt="Image">
        </v-flex>  -->
        </v-row>
        <span>Seguros Convenientes para tu Auto</span>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row justify="center">
              <v-col
              >
                <v-text-field
                  v-model="patente"
                  :rules="patenteRules"
                  label="Patente"
                  required
                  @input="v => { patente = v.toUpperCase() }"
                  filled
                  color="white"
                  background-color="#c876c0"                                                                                                                                             
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="center">
            <v-col
            >
              <v-text-field
                v-model="rut"
                :rules="rutRules"
                label="NºRut"
                @change="formatRut"
                required
                @input="v => { rut = v.toUpperCase() }"
                filled
                color="white"
                background-color="#c876c0"
              ></v-text-field>
            </v-col>
            </v-row>
            <!-- <span>Aprovecha los Descuentos en los DíasCyber</span><br/>
            <span style="font-size: 12px;">Promoción válida desde 30-08-2020 hasta 15-09-2020</span>  -->
            <v-row justify="center">            
            <v-col
              cols="12"
              md="4"
            >
            <v-btn @click="obtieneDatos()" rounded color="#771b6e" dark>INGRESAR</v-btn> 
            </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-flex>
          <img mt-2 class="block-center rounded"
          height="80px"
          max-width="80"
          max-height="80"
          src="img/logos/LogoUniversalNewIndex.jpeg" alt="Image">
        </v-flex>
      </v-col>
    </v-row>
    <template>
      <div>
        <v-alert
          v-model="alert"
          border="left"
          close-text="Close Alert"
          type="warning"
          dark
          dismissible
        >
          {{MensajeError}}
        </v-alert>
      </div>
    </template>
    <template>
      <div>
        <v-alert
          v-model="ErrorSer"
          border="left"
          close-text="Close Alert"
          type="error"
          dark
          dismissible
        >
          {{ErrorServer}}
        </v-alert>
      </div>
    </template>
    <template>
      <div class="vld-parent">
          <loading :active.sync="isLoading"
          :is-full-page="fullPage"></loading>
      </div>
    </template>
  </v-container>
</template>

<script>
  import config from '../../config.js'
  import { mapState, mapMutations } from 'vuex'
  import axios from "axios";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  const { validate, clean, format } = require('rut.js')

  export default {
    components : {
      Loading
    },
    created () {
            this.$store.commit('SET_LAYOUT', 'index-layout')
    },
    data: () => ({
      active : true,
      alert: false,
      ErrorSer : false,
      MensajeError : "",
      ErrorServer : "",
      valid: false,
      rut: '',
      patente: '',
      rutRules: [
        v => !!v || 'NºRut Requerido',
        v => validate(v) || 'NºRut Incorrecto',
      ],      
      patenteRules: [
        v => !!v || 'Patente Requerida',
        v => v.length <= 6 || 'No puede ser superior a 6 caracteres ',
      ],
      isLoading: false,
      fullPage: true
    }),
    computed: {
      ...mapState(['asegurado', 'vehiculo', 'pagador', 'tarifas'])
    },
    methods: {
      ...mapMutations(['setAsegurado','setPagador', 'setVehiculo', 'setTarifas']), 
    async obtieneDatos(){
        if (this.$refs.form.validate()) { //(this.valid) {
            this.isLoading = true;
        await axios
        .post(`${config.API_URL}/api/cts/cotizacion`, {
            documento: clean(this.rut), 
            patente : this.patente.toUpperCase()
        })
        .then(response => {
            //console.log(response.data.asegurado)
            var rutB = response.data.asegurado.documento

            let asegurado = new Object()
          //  console.log("Valor de rut " + (validate(rutB)) ? format(rutB):format(this.rut))
            asegurado.documento = (validate(rutB)) ? format(rutB):format(this.rut)
            asegurado.nombre = response.data.asegurado.nombre
            asegurado.apellido = response.data.asegurado.apellido
            asegurado.fechaNacimiento = response.data.asegurado.fechaNacimiento
            asegurado.aseguradoPagador = response.data.asegurado.aseguradoPagador
            asegurado.region = response.data.asegurado.region
            asegurado.comuna = response.data.asegurado.comuna
            asegurado.telefono = response.data.asegurado.telefono
            asegurado.email = response.data.asegurado.email

            this.setAsegurado(asegurado)

            let pagador = new Object()
            pagador.documento = response.data.pagador.documento
            pagador.nombre = response.data.pagador.nombre
            pagador.apellido =  response.data.pagador.apellido
            pagador.correo = response.data.pagador.correo
            pagador.telefono =  response.data.pagador.telefono
            this.setPagador(pagador)

            let vehiculo = new Object()
            vehiculo.marca = response.data.vehiculo.marca
            vehiculo.modelo = response.data.vehiculo.modelo
            vehiculo.anio = response.data.vehiculo.anio
            vehiculo.patente = response.data.vehiculo.patente
            vehiculo.tipoVehiculo = response.data.vehiculo.tipoVehiculo

            this.setVehiculo(vehiculo)
            this.$router.push("cotizacion")
        })
        .catch(error => {
            console.log(error)
            this.ErrorServer = "Error en el Servicio, favor comunicar con el Administrador"
            this.ErrorSer = true  
        }).finally(() => this.isLoading = false);
        
        } else {
          //console.log('URL API', config.API_URL);
          //console.log("valid false")
          this.alert = true
          this.MensajeError = "Favor revisar los datos obligatorios a ingresar"
        }
      }, 
      formatRut(){
        this.alert = false
        this.rut = format(this.rut)
      }
    }
  }
  </script>
