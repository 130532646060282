import { render, staticRenderFns } from "./Principal.vue?vue&type=template&id=6f196cd1&"
import script from "./Principal.vue?vue&type=script&lang=js&"
export * from "./Principal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VApp,VAppBar,VContainer,VContent,VFlex,VFooter,VIcon,VLayout,VRow,VToolbarTitle})
