<template>
  <v-app id="inspire">

    <v-app-bar
      app
      color="#7c167d"
      dark
    >
      <v-toolbar-title>Seguros convenientes para tu Auto</v-toolbar-title>
    </v-app-bar>

    <v-content>
      <v-layout mt-5 ml-4 justify-center>
        <v-flex xs6>
          <img ma-5 class="block-center rounded"
          height="50px"
          max-width="100"
          max-height="100"
          src="img/logos/ComparaSeguro.png" alt="Image">
        </v-flex>

        <v-flex class="d-flex flex-row-reverse" xs6>
          <img mt-2 class="block-center rounded"
          height="100px"
          max-width="100"
          max-height="100"
          src="img/logos/LogoUniversalNew.png" alt="Image">
        </v-flex>


      </v-layout>
      <v-container>
        <router-view/>
      </v-container>
    </v-content>
    <v-footer
      color="#7c167d"
      app
    >
      <v-row
      justify="center"
      >
        <!--<span class="white--text">comparatuseguro &copy; 2020</span>-->
        <div>
          <v-icon color="white darken-2">mdi-email</v-icon>
          <span class="white--text pl-1 pr-5">contacto@universalseguros.cl</span>
        </div>
        <div>  
          <v-icon color="white darken-2">mdi-whatsapp</v-icon>
          <span class="white--text">+56 9 9845 2696</span>
        </div>  
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
    }),
  }
</script>
