<template>
 <v-container>
    <div class="d-flex mb-5">
      <v-layout >
        <v-flex >
            <h1 class="title">Elegir Seguro</h1>
        </v-flex>
      </v-layout>
    </div>

    <v-form ref="form" id="CTS" @submit.prevent="">
          <input type="hidden" impulsaName="nombre-contacto" v-model="nombreContacto">
          <input type="hidden" impulsaName="email-contacto" v-model="emailContacto">
          <input type="hidden" impulsaName="telefono-contacto" v-model="telefonoContacto">
          <input type="hidden" impulsaName="nombre-cliente" v-model="nombreContacto">
          <input type="hidden" impulsaName="comentarios" v-model="mensaje">
    <v-divider class="mb-5"></v-divider>
      <v-row justify="center">
      <v-card class="ma-5" xl6>
        <v-toolbar flat>
          <template v-slot:extension>
            <v-tabs
              v-model="tabs"
              fixed-tabs
              background-color="#7c167d"
              dark
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                v-for="(i, index) in uniqueNames"
                :key="index"
                :href="'#tab-' + i"
              >
                {{  i }} UF
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-container  >
        
        <v-tabs-items v-model="tabs">
            <v-tab-item
              v-for="(item, index) in orderedPrimaCLP " 
              :key="index"
              :value="'tab-' + item.deducible"
            >
               <v-row no-gutters>
                  <v-col>
                    <v-card
                      class="pa-2"
                      outlined
                      tile
                      color="white"
                    >
                     <v-img
                        max-height="100"
                        max-width="100"
                        :src="`/img/logos/${item.logoCompania}`"
                        :alt="item.logoCompania"
                      ></v-img>
                    </v-card>
                  </v-col>

                  <v-col>
                    <v-card
                      class="pa-3"
                      outlined
                      tile
                      color="white"
                    >
                      <p v-if="item.primaMensualSeguro > 0" class="text-center overline">{{item.cuotas}} cuotas <br> {{item.primaMensualSeguro | numeralFormat('0.00')}} UF</p>
                      <p v-if="item.primaMensualSeguro > 0" class="text-center font-weight-black"> {{item.primaMensualCLP | numeralFormat('$0,0')}} </p>
                      <p v-else class="text-center font-weight-black overline"> Obtén hasta un 50% de tu actual seguro. Solicitalo con un agente </p>       
                      <p class="text-center"><v-btn x-small @click="verCobertura(item.cobertura)" rounded color="#7c167d" dark>Coberturas</v-btn></p>
                    </v-card>
                  </v-col>

                  <v-col>
                    <v-card
                      class="pa-2"
                      outlined
                      tile
                      color="white"
                    >
                     <v-btn type="submit" @click="comprarSeguro(item)" small color="#7c167d" dark>Me interesa</v-btn>
                  </v-card>
                </v-col>
              </v-row>
          </v-tab-item>
        </v-tabs-items>
            </v-container>  
        
        <v-layout ma-2 justify-center>
              <v-flex>
                  <p class="text-center font-weight-black">Cotizando para NºRut : {{rutForma}} Patente: {{vehiculo.patente}}</p>
              </v-flex>
        </v-layout>
        <v-layout ma-2 justify-center>
              <v-flex>
                  <p class="text-center">Valor referencial según valor UF del día {{conversion.fecha}}</p>
              </v-flex>
        </v-layout>
      </v-card>
      <template>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
            :is-full-page="fullPage"></loading>
        </div>
      </template>

      </v-row>
    </v-form>
  <template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Coberturas</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
                 <v-img
                    :src="'/img/logos/'+cobertura"
                  ></v-img>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  </v-container>
</template>

<script>
 import config from '../../config.js'
 import { mapState, mapMutations } from 'vuex'
 import axios from "axios";
 import Loading from 'vue-loading-overlay';
 import 'vue-loading-overlay/dist/vue-loading.css';
 const { format} = require('rut.js');
 import _ from 'lodash';
 
  export default {
    components : {
      Loading
    },
    data () {
      return {
        tabs: null,
        photoImage: '/img/logos/BCI.png',
        text: '',
        tarifasCabecera : [],
        tarifasCuerpo : [],
        isLoading: false,
        fullPage: true, 
        rutForma: "", 
        dialog : false, 
        cobertura : "",
        nombreContacto: "",      
        telefonoContacto: "",
        emailContacto: "",
        mensaje: "",
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'principal-layout'),
      this.formateaRut(),
      this.datosImpulsa()
    },
    computed: {
      uniqueNames: function() {
        console.log('Entrando a filtro', this.$store.state.tarifas);
        var filtered_array = [];
        for(var i =0; i < this.$store.state.tarifas.length; i++) {
          if(filtered_array.indexOf(this.$store.state.tarifas[i].deducible) === -1) {
            filtered_array.push(this.$store.state.tarifas[i].deducible)
          }
        }
        console.log('Resultado array::', filtered_array);
        return filtered_array;
      },
      orderedPrimaCLP: function () {
        return _.orderBy(this.tarifas, 'primaMensualCLP')
      }
      ,
      ...mapState(['asegurado', 'vehiculo', 'pagador', 'tarifas', 'conversion'])
    },
    methods: {
      ...mapMutations(['setAsegurado','setAseguradoEmision', 'setPagador', 'setVehiculo', 'setVehiculoEmision','setTarifas']), 
      async comprarSeguro(item){
        this.mensaje = `${this.mensaje}|${item.nombreCompania}|${item.deducible}|${item.primaMensualSeguro}|${item.primaMensualCLP}`;
        console.log('ITEM::', item);
        this.isLoading = true
        await axios
        .post(`${config.API_URL}/api/cts/propuesta`, 
            {
              asegurado : this.$store.state.asegurado.documento, 
              pagador : this.$store.state.pagador.documento, 
              patente : this.$store.state.vehiculo.patente, 
              cotizacion : item.cotizacion
            })
        .then(response => {
            response.data.asegurado.email = this.$store.state.asegurado.email;
            response.data.asegurado.telefono = this.$store.state.asegurado.telefono;

            this.setAseguradoEmision(response.data.asegurado)
            this.setVehiculoEmision(response.data.vehiculo)
           
           if (this.$store.state.asegurado.aseguradoPagador === 'S') {
              response.data.pagador.correo = this.$store.state.asegurado.email;
              response.data.pagador.telefono = this.$store.state.asegurado.telefono;
            }

            this.setPagador(response.data.pagador)
            this.$store.state.cotizacion = item.cotizacion
            this.$router.push("propuesta")
        })
        .catch(error => {
            console.log(error)
        }).finally(() => this.isLoading = false);
      }, 
      formateaRut(){
        this.rutForma = format(this.$store.state.asegurado.documento)
      }, 
      verCobertura(cobertura){
        //console.log("Imagen cobertura " + cobertura )
        this.cobertura = cobertura
        this.dialog = true;
      },
      datosImpulsa(){
        this.nombreContacto = `${this.$store.state.asegurado.nombre} ${this.$store.state.asegurado.apellido}`
        this.emailContacto = this.$store.state.asegurado.email;
        this.telefonoContacto = this.$store.state.asegurado.telefono;
        this.mensaje =  `${this.$store.state.vehiculo.marca}|${this.$store.state.vehiculo.modelo}|${this.$store.state.vehiculo.anio}|${this.$store.state.vehiculo.patente}|${this.$store.state.vehiculo.tipoVehiculo}|${this.$store.state.asegurado.documento}|${this.$store.state.asegurado.fechaNacimiento}|${this.$store.state.asegurado.region}|${this.$store.state.asegurado.comuna}`;    
      },      
    },
    mounted() {
      console.log('inicio tarifas.VUE');
      let externalScript = document.createElement('script')
          externalScript.setAttribute('src', 'https://app.sistemaimpulsa.com/catchform.js')
          document.head.appendChild(externalScript)    
    }
  }
</script>
