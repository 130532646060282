<template >
  <v-app id="inspire" :style="{ background: '#99248c' }">
    <v-content color="#7c167d" dark>
      <v-layout mt-5 ml-4 justify-center>
      </v-layout>
      <v-container >
        <router-view/>
      </v-container>
    </v-content>
    <v-footer
      color="#99248c"
      app
    >
      <v-row
      justify="center"
      >
        <!--<span class="white--text">comparatuseguro &copy; 2020</span>-->
        <div>
          <v-icon color="white darken-2">mdi-email</v-icon>
          <span class="white--text pl-1 pr-5">contacto@universalseguros.cl</span>
        </div>
        <div>  
          <v-icon color="white darken-2">mdi-whatsapp</v-icon>
          <span class="white--text">+56 9 9845 2696</span>
        </div>  
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
    }),
  }
</script>
