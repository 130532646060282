<template>
  <v-container>     
      <div>
        <v-form ref="form" v-model="valid" lazy-validation id="CTS" @submit.prevent="enviaCotizacion(mAsegurado, mVehiculo, mPagador)">
          <input type="hidden" impulsaName="nombre-contacto" v-model="nombreContacto">
          <input type="hidden" impulsaName="email-contacto" v-model="emailContacto">
          <input type="hidden" impulsaName="telefono-contacto" v-model="telefonoContacto">
          <input type="hidden" impulsaName="nombre-cliente" v-model="nombreContacto">
          <input type="hidden" impulsaName="comentarios" v-model="mensaje">

          <div class="d-flex mb-5">
            <v-layout >
              <v-flex >
                  <h1 class="title">Verificar Datos</h1>   
              </v-flex>
            </v-layout>       
          </div>
          <v-divider class="mb-5"></v-divider>
          <v-expansion-panels
            v-model="panel"
            :readonly="readonly"
            multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header>Datos Asegurado</v-expansion-panel-header>
              <v-divider class="mb-5"></v-divider>
              <v-expansion-panel-content>
                  <v-row justify="space-between">
                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                        >
                            <v-text-field
                              v-model="mAsegurado.documento"
                              :rules="rulesFecha"
                              label="NºRut"
                              :disabled="true"
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                        >
                            <v-text-field
                              v-model="mAsegurado.nombre"
                              :rules="rulesFecha"
                              label="Nombre"
                              :disabled="false"
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                        >
                            <v-text-field
                              v-model="mAsegurado.apellido"
                              :rules="rulesApellidos"
                              label="Apellidos"
                              :disabled="false"
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                        >
                            <v-text-field
                              v-model="mAsegurado.fechaNacimiento"
                              label="Fecha Nacimiento"
                              :rules="rulesFechaNacimiento"
                              v-mask="'##/##/####'"
                              :disabled="false"
                            ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                              v-model="mAsegurado.telefono"
                              :rules="rulesFecha"
                              label="Teléfono"
                              :disabled="false"
                              v-mask="maskF"
                            ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                              v-model="mAsegurado.email"
                              :rules="rulesFecha"
                              label="Email"
                              :disabled="false"
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                         >
                          <v-autocomplete
                            :items="region"
                            v-model="mAsegurado.region"
                            :rules="rulesRegion"
                            label="Region"
                            v-on:change="defineComuna(mAsegurado.region)"
                            :disabled="false"
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                        >
                          <v-autocomplete
                            :items="comuna"
                            v-model="mAsegurado.comuna"
                            :rules="rulesComuna"
                            label="Comuna"
                            :disabled="false"
                          ></v-autocomplete>
                        </v-col>                    
                  </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-content>
                <v-switch v-model="success" class="ma-2" label="Soy Asegurado y Pagador"></v-switch>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="mb-5" v-if="!success">
              <v-expansion-panel-header>Datos Pagador</v-expansion-panel-header>
              <v-divider class="mb-5"></v-divider>
              <v-expansion-panel-content>
                  <v-row justify="space-between">
                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                        >
                            <v-text-field
                              v-model="mPagador.documento"
                              label="NºRut"
                              :rules="rutRulesPagador"
                              @change="obtienePagador"
                              :disabled="false"
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                        >
                            <v-text-field
                              v-model="mPagador.nombre"
                              :rules="rulesFecha"
                              label="Nombre"
                              :disabled="false"
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                        >
                            <v-text-field
                              v-model="mPagador.apellido"
                              :rules="rulesFecha"
                              label="Apellido"
                              :disabled="false"
                            ></v-text-field>
                        </v-col>
                  </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Vehiculo</v-expansion-panel-header>
              <v-divider class="mb-5"></v-divider>
              <v-expansion-panel-content>
                  <v-row justify="space-between">
                        <v-col
                        cols="12"
                        xs="4"
                        sm="6"
                        md="4"
                        >
                          <v-autocomplete
                              :items="marca"
                              v-model="mVehiculo.marca"
                              :rules="rulesMarca"
                              label="Marca"
                              v-on:change="defineModelo(mVehiculo.marca)"
                              :disabled="false"
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                        cols="12"
                        xs="4"
                        sm="6"
                        md="4"
                        >
                          <v-autocomplete
                              :items="modelo"
                              v-model="mVehiculo.modelo"
                              :rules="rulesModelo"
                              label="Modelo"
                              :disabled="false"
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                        >
                            <v-text-field
                              v-model="mVehiculo.anio"
                              :rules="rulesFecha"
                              label="Año"
                              v-mask ="mask"
                              :disabled="false"
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                        >
                            <v-text-field
                              v-model="mVehiculo.patente"
                              :rules="rulesFecha"
                              label="Patente"
                              :disabled="true"
                            ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          xs="4"
                          sm="6"
                          md="4"
                        >
                          <v-autocomplete
                              :items="tipo"
                              v-model="mVehiculo.tipoVehiculo"
                              :rules="rulesFecha"
                              label="Tipo de Vehículo"
                              :disabled="false"
                          ></v-autocomplete>
                        </v-col>
                  </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row
            align="center"
            justify="center"
            class="mt-5"
          >  
          <v-btn ref="submit" type="submit" rounded x-large  color="#7c167d" dark>Buscar mi seguro</v-btn>
           <!-- <v-btn ref="submit" @click="onSubmit(mAsegurado, mVehiculo, mPagador)" rounded x-large  color="#7c167d" dark>Buscar mi seguro</v-btn> -->
          </v-row>
        </v-form>
      </div>
       <template>
      <div>
        <v-alert
          v-model="alert"
          border="left"
          close-text="Close Alert"
          type="warning"
          dark
          dismissible
        >
          {{MensajeError}}
        </v-alert>
      </div>
    </template>
    <template>
      <div>
        <v-alert
          v-model="ErrorSer"
          border="left"
          close-text="Close Alert"
          type="error"
          dark
          dismissible
        >
          {{ErrorServer}}
        </v-alert>
      </div>
    </template>
      <template>
        <div class="vld-parent">
            <loading :active.sync="isLoading"
            :is-full-page="fullPage"></loading>
        </div>
    </template>
  </v-container>
</template>
<script>
import config from '../../config.js'
import { mapState, mapMutations } from 'vuex'
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
const { validate, clean, format} = require('rut.js')
import { mask } from 'vue-the-mask'

  export default {
    components : {
      Loading
    },
    directives: {
      mask,
    },
    data: () => ({
      success: true,
      alert: false,
      ErrorSer : false,
      panel: [0, 1, 2],
      readonly: false,
      valid: false,
      mask:  '####',
      maskF : '#########',
      rut: '',
      rulesApellidos: [
        v => !!v || 'Favor ingresar ambos apellidos',
        v => v.split(" ").length >= 2 || 'Favor ingresar ambos apellidos',
        v => !!v.split(" ")[1] || 'Favor ingresar ambos apellidos',
      ],
      rutRulesPagador: [
        v => validate(v) || 'NºRut Incorrecto'
      ],
      patente: '',
      rulesFecha: [
        v => !!v || 'Favor ingresar información',
      ],
      rulesFechaNacimiento: [
        v => !!v || 'Ingresar fecha de nacimiento',
      ],
      rulesRegion: [
        v => !!v || 'Region Requerida',
      ],
      rulesComuna: [
        v => !!v || 'Comuna Requerida',
      ],
      rulesMarca: [
        v => !!v || 'Marca Requerido',
      ],
      rulesModelo: [
        v => !!v || 'Modelo Requerido',
      ],
      ModelTipoUso:'',
      tipoUso:['Particular'],
      modelModelo:'',
      modelo:[],
      ModelMarca:'',
      marca:[],
      marcaH:[], 
      tipo : [], 
      region : [],
      regionH : [],
      modelTipo : '', 
      comuna: [],
      mPagador : {
        documento  : '',
        nombre     : '', 
        apellido   :  '', 
        correo     : '',
        telefono   : ''
      }, 
      mAsegurado: {
            documento : '', 
            nombre: '', 
            apellido: '', 
            fechaNacimiento : '', 
            aseguradoPagador : '', 
            direccion : '', 
            email : '', 
            telefono : '', 
            region : '', 
            comuna : ''
      }, 
      mVehiculo: {
            marca :   '', 
            modelo:   '', 
            anio:     '', 
            patente : '', 
            tipoVehiculo : '', 
            tipoUso : '', 
            color :  '', 
            chasis : '', 
            numeroMotor : ''
      },
      isLoading: false,
      fullPage: true,
      MensajeError:"",
      ErrorServer:"",
      nombreContacto: "",      
      telefonoContacto: "",
      emailContacto: "",
      mensaje: "",
      contador: 0
    }),
    created () {
      this.$store.commit('SET_LAYOUT', 'principal-layout')
      this.inicial()
    },
    computed: {
      ...mapState(['asegurado', 'vehiculo', 'pagador', 'tarifas', 'isLoadImpulsa'])
    },
    methods: {
       ...mapMutations(['setAsegurado','setPagador', 'setVehiculo', 'setTarifas', 'setconversion']),
      async inicial(){
        this.isLoading = true

        await axios
        .get(`${config.API_URL}/api/cts/comunas`)
        .then(response => {
            //console.log("Obtiene comunas")
            response.data.communes.regiones.forEach(element => {
              let region = new Object()
              region.NombreRegion = element.NombreRegion
              region.numero = element.numero
              region.comunas = element.comunas
              this.regionH.push(region)
              this.region.push(element.NombreRegion)
            });
        })
        .catch(error => {
          console.log(error)
        })
        this.region.sort()

   /*      await axios
        .get(`${config.API_URL}/api/cts/comunas`)
        .then(response => {
            response.data.communes.regiones.forEach(element => {
              let region = new Object()
              region.NombreRegion = element.NombreRegion
              region.numero = element.numero
              region.comunas = element.comunas
              this.regionH.push(region)
              this.region.push(element.NombreRegion)
            });
        })
        .catch(error => {
          console.log(error)
        })
        this.region.sort() */
      /*
        await axios
        .get(`${config.API_URL}/api/cts/marcas`)
        .then(response => {
          response.data.modelBrands.forEach(element => {
            let marca = new Object()
            marca.nombre = element.brand
            marca.modelo = element.models
            this.marcaH.push(marca)
            this.marca.push(element.brand)
          });
        })
        .catch(error => {
            console.log(error)
        }).finally(() => this.isLoading = false);
        this.marca.sort() 
        */
       //Nuevo modelo 
       await axios
        .get(`${config.API_URL}/api/v2/brands`)
        .then(response => {
          response.data.forEach(element => {
            //let marca = new Object()
            //marca.nombre = element.brand
            //marca.modelo = element.models
            //this.marcaH.push(marca)
            this.marca.push(element)
          });
          this.marca.sort();
        })
        .catch(error => {
            console.log(error)
        }).finally(() => this.isLoading = false);
        this.marca.sort() 


        axios
        .get(`${config.API_URL}/api/cts/tipoVehiculos`)
        .then(response => {
            response.data.TipoVehiculos.forEach(tipo => {
                this.tipo.push(tipo.descripcion)
            })
        })
        .catch(error => {
            console.log(error)
        })

        this.setModelAsegurado(this.$store.state.asegurado)
        this.setModelPagador(this.$store.state.pagador)
        this.setModelVehiculo(this.$store.state.vehiculo)
      },
      defineComuna(region){ 
        console.log('REGION BUSQUEDA:', region); 
        this.comuna = [];        
        this.regionH.forEach(element => {          
          if (element.NombreRegion === region){            
            element.comunas.forEach(comuna => {              
              this.comuna.push(comuna)
            })
          }
          /*
          else {
            console.log("No encontro comunas")
          }
          */
        });

        this.comuna.sort()
      },
      setModelAsegurado(asegurado){
          this.mAsegurado.documento = asegurado.documento
          this.mAsegurado.nombre = asegurado.nombre
          this.mAsegurado.apellido = asegurado.apellido
          this.mAsegurado.fechaNacimiento = asegurado.fechaNacimiento
          this.mAsegurado.aseguradoPagador = asegurado.aseguradoPagador
          this.mAsegurado.direccion = asegurado.direccion
          this.mAsegurado.email = asegurado.email
          this.mAsegurado.region = asegurado.region
          this.defineComuna(this.mAsegurado.region);
          this.mAsegurado.comuna = asegurado.comuna
          this.mAsegurado.telefono = asegurado.telefono
      },
      setModelPagador(pagador){
          this.mPagador.documento = pagador.documento
          this.mPagador.nombre = pagador.nombre
          this.mPagador.apellido = pagador.apellido
          this.mPagador.correo = pagador.correo
          this.mPagador.telefono = pagador.telefono

      },
      setModelVehiculo(vehiculo){
          this.defineModelo(vehiculo.marca)

          this.mVehiculo.marca = vehiculo.marca
          this.mVehiculo.modelo = vehiculo.modelo
          this.mVehiculo.anio = vehiculo.anio
          this.mVehiculo.patente = vehiculo.patente
          this.mVehiculo.tipoVehiculo = vehiculo.tipoVehiculo
          this.mVehiculo.tipoUso = vehiculo.tipoUso
          this.mVehiculo.color = vehiculo.color
          this.mVehiculo.chasis = vehiculo.chasis
          this.mAsegurado.numeroMotor = vehiculo.numeroMotor

      }, 
      async enviaCotizacion(asegurado, vehiculo, pagador){    
        if (this.$refs.form.validate()) { //this.valid) {
           
            this.alert = false
            this.isLoading = true
            let aseguradoG = new Object()
            aseguradoG.documento = asegurado.documento
            aseguradoG.nombre = asegurado.nombre
            aseguradoG.apellido = asegurado.apellido
            aseguradoG.fechaNacimiento = asegurado.fechaNacimiento
            aseguradoG.aseguradoPagador = (this.success ? "S" : "N")
            aseguradoG.comuna = asegurado.comuna
            aseguradoG.region = asegurado.region
            aseguradoG.email = asegurado.email
            aseguradoG.telefono = asegurado.telefono

            this.setAsegurado(aseguradoG)
            
            let pagadorG = new Object()

            if (this.success) {
                pagadorG.documento = asegurado.documento
                pagadorG.nombre = asegurado.nombre
                pagadorG.apellido =  asegurado.apellido
            }else {
                pagadorG.documento = pagador.documento
                pagadorG.nombre = pagador.nombre
                pagadorG.apellido =  pagador.apellido
            }
            this.setPagador(pagadorG)

            let vehiculoG = new Object()
            vehiculoG.marca = vehiculo.marca
            vehiculoG.modelo = vehiculo.modelo
            vehiculoG.anio = vehiculo.anio
            vehiculoG.patente = vehiculo.patente
            vehiculoG.tipoVehiculo = vehiculo.tipoVehiculo
            this.setVehiculo(vehiculoG)

           await axios
            .post(`${config.API_URL}/api/cts/tarifas`, 
                {
                  asegurado : aseguradoG, 
                  pagador : pagadorG, 
                  vehiculo : vehiculoG
                })
            .then(response => {   
              console.log('response=>',response.data);      
              if (response.data.error) {
                console.log('Existe error en tarifas');
                this.ErrorServer = response.data.error;
                this.ErrorSer = true 
                return;
              }

              this.setTarifas(response.data.tarifas);              
              this.setconversion(response.data.conversion);
              this.$router.push("tarifas")
            })
            .catch(error => {
                console.log('Error=>',error.data)

                this.ErrorServer = "Error en el Servicio, favor comunicar con el Administrador"
                this.ErrorSer = true 
            }).finally(() => this.isLoading = false);
        }else {
            this.MensajeError = "Favor revisar los datos obligatorios a ingresar"
            this.alert = true
        }
        
      },
      async defineModelo(pMarca){
        this.isLoading = true;
        console.log('buscando modelos para marca, ',pMarca);
        this.modelo = [];
        /*
        this.marcaH.forEach(marca => {
          if (marca.nombre === pMarca) {

            marca.modelo.forEach(modelo => {
              this.modelo.push(modelo)
            })

          }
        });
        */
        await axios
        .get(`${config.API_URL}/api/v2/models`, {params: {brand: pMarca}})
        .then(response => {
          response.data.forEach(element => {
            this.modelo.push(element)
          });
        }).catch(error => {
          console.log('Error al consultar modelos:',error);
        })
        this.modelo.sort();
        this.isLoading = false;
      }, 
      async obtienePagador () {
        //console.log(this.mPagador.documento)
        this.isLoading = true
        this.mPagador.documento = format(this.mPagador.documento)
        await axios
        .get(`${config.API_URL}/api/users?taxId=`+clean(this.mPagador.documento))
        .then(response => {

            this.mPagador.nombre = response.data.data.firstname
            this.mPagador.apellido = response.data.data.lastname
    
          
        })
        .catch(error => {
            console.log(error)
            this.mPagador.nombre = ""
            this.mPagador.apellido = ""
        }).finally(() => this.isLoading = false);

        this.isLoading = false
      }, 
      limpiarrut(){
        clean(this.mPagador.documento)
      },
      datosImpulsa(){
        this.nombreContacto = this.mAsegurado.nombre+" "+this.mAsegurado.apellido; //`${this.$store.state.asegurado.nombre} ${this.$store.state.asegurado.apellido}`
        this.emailContacto = this.mAsegurado.email; //this.$store.state.asegurado.email;
        this.telefonoContacto = this.mAsegurado.telefono; //this.$store.state.asegurado.telefono;
        this.mensaje =  `${this.mVehiculo.marca}|${this.mVehiculo.modelo}|${this.mVehiculo.anio}|${this.mVehiculo.patente}|${this.mVehiculo.tipoVehiculo}|${this.mAsegurado.documento}|${this.mAsegurado.fechaNacimiento}|${this.mAsegurado.region}|${this.mAsegurado.comuna}`;    
      },
    },
    mounted() {            
        /* if (!this.$store.state.isLoadImpulsa) {        
          let externalScript = document.createElement('script')
          externalScript.setAttribute('src', 'https://app.sistemaimpulsa.com/catchform.js')
          document.head.appendChild(externalScript)
        }
          
        this.$store.commit('SET_IMPULSA', true)  
        console.log('Mounted');     */
        
    },    
  }
  </script>
