import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/components/Index.vue'
import Cotizacion from '@/components/Cotizacion.vue'
import Propuesta from '@/components/Propuesta.vue'
import Emision from '@/components/Emision.vue'
import Tarifas from '@/components/Tarifas.vue'
//import About from '@/views/About.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/cotizacion',
    name: 'cotizacion',
    component: Cotizacion
  },
  {
    path: '/propuesta',
    name: 'propuesta',
    component: Propuesta
  },
  {
    path: '/emision',
    name: 'emision',
    component: Emision
  },
  {
    path: '/tarifas',
    name: 'tarifas',
    component: Tarifas
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: About
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
