<template>
  <v-container>
      <div>
            <v-layout >
              <v-flex :class="'d-flex align-end flex-column'">
                <!--
                   <v-img
                    max-height="50"
                    max-width="50"
                    :src="'/img/iconopasocierre.png'"
                  ></v-img>
                  -->
              </v-flex>
            </v-layout>
            <v-divider class="mb-5"></v-divider>
            <h3>Gracias por su preferencia</h3>
            <v-row
                align="center"
                justify="center"
                class="mt-5"
            >
                <p>Estamos enviando un mail con tu propuesta de seguro automotriz. Te contactaremos para finalizar el proceso de contratación.</p>
                <br />
                <br />
            </v-row>
        </div>
        <v-btn to="/" rounded x-large  color="#7c167d" dark>Volver a Inicio</v-btn>
  </v-container>
</template>
<script>
  export default {
    created () {
      this.$store.commit('SET_LAYOUT', 'principal-layout')
    },
    data: () => ({
      panel: [0, 1],
      readonly: false,
      valid: false,
      rut: '',
      rutRules: [
        v => !!v || 'NºRut Requerido',
      ],
      patente: '',
      patenteRules: [
        v => !!v || 'Patente Requerida',
      ],
      modelRegion: '',
      region: [
          'Region Metropolitana',
          'Antofagasta',
          'Arica y Parinacota',
          'Atacama',
        ],
      modelComuna: '',
      comuna: [
          'Algarrobo ',
          'Buin ',
          'Dalcahue ',
          'Florida ',
        ],
      ModelTipoUso:'',
      tipoUso:['Particular'],
      modelModelo:'',
      modelo:['Aveo', 'Accent','Qashqai' ],
      ModelMarca:'',
      marca:['Nissan', 'Chevrolet', 'Hyundai'],


    }),
  }
  </script>
